export default {
    seo: {
      title: 'Financial statements for 2020 | Bank 131',
      description: 'Financial statements for 2020'
    },
    title: 'Financial statements for 2020',
    content: [
         {
             label: 'Posting date 04.03.2021',
             title: 'Annual accounting (financial) statements for 2020.zip',
             link: '/legal/en/Annual_accounting_(financial)_statements_for_2020_en.zip',
         },
         {
             label: 'Posting date 12.11.2020',
             title: 'Published reports for 9 months of 2020.zip',
             link: '/legal/en/Published_reports_for_9_months_of_2020.zip',
         },
         {
             label: 'Posting date 10.08.2020',
             title: 'Published reports for first half of 2020.zip',
             link: '/legal/en/Published_reports_for_first_half_of_2020.zip',
         },
         {
             label: 'Posting date 18.05.2020',
             title: 'Published financial statements for the first quarter of 2020.zip',
             link: '/legal/en/1_kvartal_2020_en.zip',
             clarification: {
                 label: 'Posting date 29.04.2020',
                 text: 'Annual shareholder meeting (Bank 131 Joint Stock Company`s sole shareholder decision) was held on April 27, 2020.'
             }
         }
     ]
}